import {
    Switch,
    Icons,
    Table,
    Input, Space, Button, Form, Popover, notification, TextField, useEditableTable
} from '@pankod/refine-antd';
import type { ColumnsType } from 'antd/es/table';
import {IProdotti} from 'interfaces';
import axios from 'axios';
import {TOKEN_KEY} from '../../constants';
import {CrudFilters, HttpError, useApiUrl, useUpdate} from '@pankod/refine-core';
import {useEffect, useState} from 'react';
import { Dayjs } from "dayjs";
import {Progress} from 'antd';

export const ProdottiList: React.FC = (props) => {
    const apiUrl = useApiUrl();
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [form] = Form.useForm();

    const { tableQueryResult, tableProps,
        isEditing,
        setId: setEditId,
        searchFormProps
        } = useEditableTable<IProdotti, HttpError, { description: string; category: IProdotti; createdAt: [Dayjs, Dayjs] }>({
        resource: "prodottis",
        metaData: { populate: '*'},
        permanentFilter: [
            {
                field: "description",
                operator: "contains",
                value: description,
            },
            {
                field: "category][description",
                operator: "contains",
                value: category,
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            filters.push(
                {
                    field: "description",
                    operator: "contains",
                    value: description,
                },
                {
                    field: "category][description",
                    operator: "contains",
                    value: category,
                },
            );

            return filters;
        },

    });

    function onChange(record: any, checked: boolean) {
        axios.post(apiUrl + '/updateStocks', {
                idProduct: record.idProduct,
                manageStock: checked
        }, {headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            console.log(res);
        });
    }

    function syncEtichette() {


    }
    function syncProdotti() {
        setIsLoading(true);
        axios.get(apiUrl + '/syncProdottiFromCassa', {
            headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            console.log(res);
            if (res && res.status === 200) {
                const i = setInterval(() => {
                    axios.get(apiUrl + '/jobs/' + res.data.id, {
                        headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
                        if (res && res.data.data.attributes.isCompleted) {
                            setIsLoading(false);
                            clearInterval(i);
                            setValue(res.data.data.attributes.progressivo);
                            notification.open({
                                message: 'Notification Title',
                                description:
                                    'Prodotti Aggiornati Correttamente.',
                                onClick: () => {
                                    console.log('Notification Clicked!');
                                },
                            });
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000)
                        } else {
                            setValue(res.data.data.attributes.progressivo);
                        }
                    });
                }, 3000)
            }
        });
    }

    function syncGiacenze() {
        setIsLoading(true);
        axios.get(apiUrl + '/updateGiacenzeFromCassa', {
            headers: {"Authorization": "Bearer " + localStorage.getItem(TOKEN_KEY)}}).then((res: any) => {
            console.log(res);
            if (res && res.status === 200) {
                setIsLoading(false);
                tableQueryResult.refetch().then()
            } else {
                console.log('Errore');
            }
        });
    }

    const { mutate } = useUpdate<any>();
    const updateSoglia = (value: any, record: any) => {
        console.log(record);
        mutate({
            resource: "updateSogliaAllarme",
            id: record.stocks.idProduct,
            values: {
                idInt: record.id,
                stocks: record.stocks,
                id: record.stocks.idProduct,
                idAccount: record.idAccount,
                idSalesPoint: record.idSalesPoint,
                idSku: record.idSku,
                warningLevel: Number(value),
                live: true
            },
        });
        tableQueryResult.refetch().then();
    };

    const resetFilter = () => {
        setCategory('');
        setDescription('');
        form.resetFields();
    };

    const columns: ColumnsType<IProdotti> = [
        {
            title: 'Codice',
            dataIndex: 'externalId',
            key: 'externalId',
            width: '10%',
            render: (value, record: IProdotti) => (
                value.split('_')[0]
            )
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
            key: 'description',
            width: '30%',
        },
        {
            title: 'Prezzo',
            dataIndex: 'prices',
            key: 'prices',
            width: '10%',
            render: (value, record: IProdotti) => (
                record?.prices[0]?.value + ' €'
            )
        },
        {
            title: 'Magazzino',
            dataIndex: 'stocks',
            key: 'stocks',
            width: '10%',
            render: (value, record: IProdotti) => (
                <Switch defaultChecked={Boolean(record?.stocks?.manageStock)} checkedChildren="SI" unCheckedChildren="NO" onChange={(checked, event) => onChange(record, checked)}/>
            )
        },
        {
            title: 'Soglia',
            dataIndex: 'warningLevel',
            key: 'warningLevel',
            width: '5%',
            render: (value, record: IProdotti) => {
                if (isEditing(record.id)) {
                    return (
                        <Form.Item
                            name="warningLevel"
                            style={{ margin: 0 }}
                        >
                            <Input placeholder={record?.stocks?.warningLevel?.toString()} onPressEnter={(e:any) => updateSoglia(e.target.value, record)}/>
                        </Form.Item>
                    );
                }
                return <TextField value={record?.stocks?.warningLevel}/>
            }
        },
        {
            title: 'Giacenza',
            dataIndex: 'stockLevel',
            key: 'stockLevel',
            width: '8%',
            render: (value, record: IProdotti) => (
                record?.stocks?.stockLevel
            )
        },
        {
            title: 'Incoming',
            dataIndex: 'incomingQuantity',
            key: 'incomingQuantity',
            width: '8%',
            render: (value, record: IProdotti) => (
                record?.stocks?.incomingQuantity
            )
        },
        {
            title: 'Categoria',
            dataIndex: 'category',
            key: 'category',
            width: '30%',
            render: (value, record: IProdotti) => (
                record?.category.description
            )
        }
    ];

    const handleVisibleChange = (newVisible: boolean) => {
        setVisible(newVisible);
    };

    useEffect(() => {
        syncGiacenze();
    }, [])
    return (
        <>
            <div>
                <Button htmlType="button" type="primary" onClick={() => syncProdotti()} style={{
                    marginRight: 10
                }} loading={isLoading}>
                    Aggiorna da Cassa In Cloud
                </Button>
                <Button htmlType="button" type="default" onClick={() => syncGiacenze()} style={{
                    marginRight: 10
                }} loading={isLoading}>
                    Aggiorna Giacenze da Cassa In Cloud
                </Button>
                <Button htmlType="button" type="primary" onClick={() => syncEtichette()} style={{
                    marginRight: 10
                }} loading={isLoading}>
                    Aggiorna Prezzo Etichette
                </Button>
                <div hidden={!isLoading} style={{ marginTop: 30}}>
                    <Progress
                        strokeColor={{
                            from: '#108ee9',
                            to: '#87d068',
                        }}
                        percent={((isLoading) ? value : 99)}
                        status={((isLoading) ? 'active' : 'success')}
                    />
                </div>
                <Popover
                    placement="leftTop"
                    style={{
                        float: "right"
                    }}
                    content={<Form form={form} layout="horizontal" {...searchFormProps} labelCol={{ span: 8 }}
                                   wrapperCol={{ span: 16 }}>
                        <Form.Item label="Descrizione" name="description">
                            <Input
                                placeholder="Descrizione"
                                prefix={<Icons.SearchOutlined />}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item label="Category" name="category">
                            <Input
                                placeholder="Categoria"
                                prefix={<Icons.SearchOutlined />}
                                onChange={(e) => setCategory(e.target.value)}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Space>
                                <Button htmlType="submit" type="primary" onClick={() => handleVisibleChange(false)}>
                                    Filter
                                </Button>
                                <Button htmlType="button" type="primary" onClick={resetFilter}>
                                    Reset
                                </Button>
                            </Space>

                        </Form.Item>
                    </Form>}
                    title="Custom Filter"
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                >
                    <Button type="primary" style={{marginBottom: 15}}>Filter</Button>
                </Popover>
                <Table {...tableProps} rowKey="id" onRow={(record) => ({
                    // eslint-disable-next-line
                    onClick: (event: any) => {
                            if (event.target.nodeName === "TD") {
                                setEditId && setEditId(record.id);
                            }
                        },
                    })}
                       columns={columns} scroll={{ y: 650 }} size="small" />
            </div>
        </>
    );
};
