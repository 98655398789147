import {
    DatePicker,
    Table,
    Select,
    Input,
    Row,
    Col,
    Form,
    DatePickerProps,
    useSelect, Button
} from '@pankod/refine-antd';
import {IFornitori, IProdotti} from 'interfaces';
import React, {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {DeleteOutlined} from '@ant-design/icons';
import {useCreate, useList} from '@pankod/refine-core';
import {useNavigate} from 'react-router-dom';
import tableExport from 'antd-table-export';

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
export const OrdiniList: React.FC = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const inputRef = useRef(null);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [totale, setTotale] = useState<number>(0);
    const [numero, setNumero] = useState(0);
    const getLastNumber = useList<any>({
        resource: 'getLastNumberOrder',
        queryOptions: {
            enabled: true
        }
    });
    const getSoldByItems = useList<any>({
        resource: 'getItemForAutoOrders',
        queryOptions: {
            enabled: false
        }
    });

    const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Codice',
            dataIndex: 'externalId',
            key: 'externalId',
            width: '10%',
        },
        {
            title: 'Prodotto',
            dataIndex: 'description',
            key: 'description',
            width: '40%',
        },
        {
            title: 'Quantità',
            dataIndex: 'quantita',
            key: 'quantita',
            width: '10%',
            render: (value, record: any) => (
                <Form.Item
                    style={{ margin: 0 }}
                    name={'quantita'}
                    rules={[
                        {
                            required: true,
                            message: `Quantita is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} placeholder="0" defaultValue={value} onKeyUp={() => handleSave(record, inputRef.current, dataSource)}/>
                </Form.Item>
            )
        },
        {
            title: 'Costo Unitario',
            dataIndex: 'prices',
            key: 'prices',
            width: '15%',
            render: (value, record: any) => (
                Number(parseFloat(value[0].value)).toLocaleString('de', {minimumFractionDigits: 2}) + ' €'
            )
        },
        {
            title: 'Importo',
            dataIndex: 'importo',
            key: 'importo',
            width: '15%',
            render: (value, record: any) => (
                value ? Number(parseFloat(value).toFixed(2)).toLocaleString('de', {minimumFractionDigits: 2}) + ' €' : 0
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (value, record: any) => (
                <DeleteOutlined onClick={() => handleDelete(record)}/>
            )
        },
    ];

    useEffect(() => {
        getLastNumber.refetch().then( (res: any) => {
            if (res.isSuccess) {
                console.log(res?.data?.data?.lastNumber);
                form.setFieldsValue({'numeroOrdine': res?.data?.data?.lastNumber + 1})
                form.setFieldsValue({'data': dayjs()})
                setNumero(res?.data?.data?.lastNumber + 1);
            }

        })
        getSoldByItems.refetch().then( (d: any) => {
            // const ord = [];
            // for (let p of d.data.data) {
            //     console.log(p);
            //     ord.push({
            //         key: p.idItem,
            //         idSku: p.item.sku[0]?.id,
            //         idItem: p.idItem,
            //         idCategory: p.item.category.id,
            //         idDepartment: p.item.department.id,
            //         idTax: p.item.department.tax.id,
            //         description: p.item.description,
            //         quantita: Number(p.quantity),
            //         prices: [{
            //             value: p.profit / p.quantity
            //             }],
            //         importo: p.profit
            //     })
            // }
            setDataSource(d.data.data);
            setTimeout(() => {
                handleSave(d.data.data[0], {input: {value: d.data.data[0].quantita}}, d.data.data)
            }, 1000)
        })
    }, [])


    const handleDelete = (key: any) => {
        const newData = dataSource.filter(item => item.id !== key.id);
        setDataSource(newData);
        setTotale(sum(newData))
    };

    const sum = (d: any) => {
        const sum = d.reduce((prev: any, curr: any, index: number, array: any) => (Number(prev) + Number(curr.importo)).toFixed(2), 0)
        return sum
    }

    const handleSave = (row: any, value: any, data?: any) => {
        const newData = [...data];
        const index = newData.findIndex(item => row.id === item.id);
        const item = newData[index];
        item.quantita = value.input.value;
        item.importo = (Number(item.prices[0].value) * Number(value.input.value)).toFixed(2);
        // newData.splice(index, 1, {
        //      ...item,
        //      ...row,
        // });
        setDataSource(newData);
        setTotale(sum(newData));
    };

    const onFinish = (values: any) => {
        // console.log('Received values of form: ', values);
        const prodotti = [];
        let index = 0;
        for (let p of dataSource) {
            index++;
            prodotti.push(
                {
                    "stockMovementEnabled": true,
                    "subtotal": false,
                    "refund": false,
                    "live": true,
                    "quantity": Number(p.quantita),
                    "confirmedQuantity": Number(p.quantita),
                    "idSku": p.idSku,
                    "idItem": p.idItem,
                    "idCategory": p.idCategory,
                    "idDepartment": p.idDepartment,
                    "cost": p.prices[0].value,
                    "idTax": p.idTax,
                    "rowNumber": index
                }
            )
        }
        mutate({
            resource: "postDocumentoOrdine",
            values: {
                numeroOrdine: numero,
                amount: Number(totale),
                prodotti: prodotti,
                supplier: form.getFieldValue('fornitore'),
                dataOrdine: form.getFieldValue('data')
            },
        });
        setTimeout(() => {
            navigate("/documentis")
            const exportInstance = new tableExport(dataSource, [
                {
                    title: 'externalId',
                    dataIndex: 'externalId',
                },
                {
                    title: 'Prodotto',
                    dataIndex: 'description',
                },
            ]);
            exportInstance.download("Timbrature_" + dayjs(), 'xlsx')
        }, 1000);

    };


    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };
    const { selectProps } = useSelect<IFornitori>({
        resource: "getSupplier",
        optionLabel: "name",
        optionValue: "id",
    });

    const prodotti = useSelect<IProdotti>({
        resource: "prodottis",
        filters: [
            {
                value: true,
                operator: 'eq',
                field: 'stocks][manageStock'
            }
        ],
        metaData: {
            populate: "prices, stocks",
        },
        optionLabel: "description",
        optionValue: "id",
    });

    const addToCart = (p: number) => {
        const pro = prodotti.queryResult.data?.data.find(x => x.id === p);
        // console.log(pro);
        // @ts-ignore
        setDataSource([...dataSource, pro]);
        // console.log(dataSource);
    }
    const { mutate } = useCreate<any>();
    const saveData = () => {
        form.submit();
    }

    return (
        <>
            <div>
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={28}>
                        <Col span={6} key={'fornitore'}>
                            <Form.Item
                                name={'fornitore'}
                                label={'Fornitore'}
                                rules={[
                                    {
                                        required: true,
                                        message: `Fornitore Richiesto!.`,
                                    },
                                ]}
                            >

                                <Select placeholder={'Seleziona Fornitore'} {...selectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key={'data'}>
                            <Form.Item
                                name={'data'}
                                label={'Data'}
                            >

                                <DatePicker defaultValue={dayjs()} format={'DD/MM/YYYY'} onChange={() => onChange} />
                            </Form.Item>
                        </Col>
                        <Col span={4} key={'numeroOrdine'}>
                            <Form.Item
                                name={'numeroOrdine'}
                                label={'Numero Ordine'}
                            >

                                <Input placeholder="N°" />
                            </Form.Item>
                        </Col>
                        <Col span={6} key={'prodotto'}>
                            <Form.Item
                                name={'prodotto'}
                                label={'Prodotto'}
                            >

                                <Select placeholder={'Seleziona Prodotto'} {...prodotti.selectProps} allowClear={true} onSelect={(p:any) => addToCart(p)} />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button type="primary" onClick={() => {saveData()}}>Salva</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Table
                    dataSource={dataSource}
                    rowKey="externalId"
                    columns={columns}
                    scroll={{ y: 650 }}
                    size="small"
                    footer={() => (<>
                        <Row>
                            <Col span={20}>
                            </Col>
                            <Col span={4}>
                                <span>Totale: { Number(parseFloat(totale.toString()).toFixed(2)).toLocaleString('de', {minimumFractionDigits: 2}) } €</span>
                            </Col>
                        </Row>
                            <Row style={{marginTop: 15}}>
                                <Col span={20}>

                                </Col>
                                <Col span={4}>
                                    {/*<Button type="primary">Salva</Button>*/}
                                </Col>
                            </Row>
                    </>)}
                />
            </div>
            {/*<div>*/}
            {/*    <Row style={{marginTop: 15}}>*/}
            {/*        <Col span={22}>*/}
            {/*            <Button type="primary">Salva</Button>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</div>*/}
        </>
    );
};
