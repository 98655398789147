import {
    DatePicker,
    Table,
    Select,
    Input,
    Row,
    Col,
    Form,
    DatePickerProps,
    useSelect, Button
} from '@pankod/refine-antd';
import {IFornitori} from 'interfaces';
import React, {useEffect, useRef, useState} from 'react';
import dayjs from 'dayjs';
import {DeleteOutlined} from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';
import {useOne, useUpdate} from '@pankod/refine-core';

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
export const OrdiniEdit: React.FC = (props) => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const inputRef = useRef(null);
    const [dataSource, setDataSource] = useState<any[]>([]);
    const [totale, setTotale] = useState(0);
    const {id} = useParams();
    const [idDocumento, setIdDocumento] = useState('');
    const { mutate } = useUpdate<any>();
    const { data, refetch} = useOne<any>({
        resource: "getRigheDocumento",
        id: idDocumento,
        queryOptions: {
            enabled: false
        }
    });

    const { selectProps: fornitoreSelectProps } = useSelect<IFornitori>({
        resource: "getAllSupplier",
        optionLabel: "name",
        optionValue: "id"
    });

    useEffect(() => {
        if (id) {
            setIdDocumento(id);
            getRighe(idDocumento);
        }
    }, [])

    const columns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Codice',
            dataIndex: 'externalId',
            key: 'externalId',
            width: '10%',
        },
        {
            title: 'Prodotto',
            dataIndex: 'description',
            key: 'description',
            width: '40%',
        },
        {
            title: 'Quantità',
            dataIndex: 'quantita',
            key: 'quantita',
            width: '10%',
            render: (value, record: any) => (
                <Form.Item
                    style={{ margin: 0 }}
                    name={'quantita'}
                    rules={[
                        {
                            required: true,
                            message: `Quantita is required.`,
                        },
                    ]}
                >
                    <Input ref={inputRef} placeholder="0" defaultValue={value} onKeyUp={() => handleSave(record, inputRef.current)}/>
                </Form.Item>
            )
        },
        {
            title: 'Costo Unitario',
            dataIndex: 'prices',
            key: 'prices',
            width: '15%',
            render: (value, record: any) => (
                Number(parseFloat(value[0].value)).toLocaleString('de', {minimumFractionDigits: 2}) + ' €'
            )
        },
        {
            title: 'Importo',
            dataIndex: 'importo',
            key: 'importo',
            width: '15%',
            render: (value, record: any) => (
                value ? Number(parseFloat(value).toFixed(2)).toLocaleString('de', {minimumFractionDigits: 2}) + ' €' : 0
            )
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '10%',
            render: (value, record: any) => (
                <DeleteOutlined onClick={() => handleDelete(record)}/>
            )
        },
    ];

    const getRighe = (id: string) => {
        console.log('Documento:', id);
        setTimeout( () => {
            refetch().then( (res: any) => {
                form.setFieldsValue({'fornitore':  {label: res?.data?.data[0]?.supplier?.name, value: res?.data?.data[0]?.supplier?.id}})
                form.setFieldsValue({'numeroOrdine': (res?.data?.data[0].hasOwnProperty('order') ? res?.data?.data[0]?.order?.number : res?.data?.data[0]?.invoice?.number)})
                form.setFieldsValue({'data': dayjs(res?.data?.data[0]?.datetime)})
                setTotale(res?.data?.data[0]?.amount)
                    const newData = [];
                    for (let p of res?.data?.data[0]?.purchaseDocumentItems) {
                        newData.push({
                            key: p.idItem,
                            externalId: p.sku.externalId.split('_')[0],
                            idSku: p.idSku,
                            idItem: p.idItem,
                            idCategory: p.idCategory,
                            idDepartment: p.idDepartment,
                            description: p.sku.descriptionLabel,
                            quantita: Number(p.quantity),
                            prices: [{
                                value: p.cost
                            }],
                            importo: p.cost * Number(p.quantity)
                        })
                    }
                    setDataSource(newData)
            });
        }, 500)
    };

    const handleDelete = (key: any) => {
        const newData = dataSource.filter(item => item.id !== key.id);
        setDataSource(newData);
        setTotale(sum(newData));
    };

    const sum = (d: any) => {
        return d.reduce((prev: any, curr: any, index: number, array: any) => (Number(prev) + Number(curr.importo)).toFixed(2), 0)
    }

    const handleSave = (row: any, value: any) => {
        const newData = [...dataSource];
        const index = newData.findIndex(item => row.idItem === item.idItem);
        const item = newData[index];
        item.quantita = Number(value.input.value);
        item.importo = (Number(item.prices[0].value) * Number(value.input.value)).toFixed(2);
        // newData.splice(index, 1, {
        //      ...item,
        //      ...row,
        // });
        setDataSource(newData);
        setTotale(sum(newData));
        setTimeout(() => {
            console.log(row);
            if (!row.hasOwnProperty('new') && !row.new) {
                for (let d of data?.data) {
                    d.amount = sum(newData);
                    d.purchaseDocumentItems[index].quantity = Number(value.input.value);
                    d.purchaseDocumentItems[index].confirmedQuantity = Number(value.input.value);
                }
            }

        }, 600)
    };

    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
    };

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };


    const prodotti = useSelect<any>({
        resource: "prodottis",
        metaData: {
            populate: "prices",
        },
        optionLabel: "description",
        optionValue: "id",
    });

    const addToCart = (p: number) => {
        const pro = prodotti.queryResult.data?.data.find(x => x.id === p);
        if (pro) pro.new = true;
        // @ts-ignore
        setDataSource([...dataSource, pro]);
    }

    const saveData = () => {
        delete data?.data[0].userFO;
        delete data?.data[0].salesPoint;
        data?.data[0].purchaseDocumentItems.pop();
        let index = 0;
        for (let p of dataSource) {
            index++;
            data?.data[0].purchaseDocumentItems.push(
                {
                    "stockMovementEnabled": true,
                    "subtotal": false,
                    "refund": false,
                    "live": true,
                    "quantity": Number(p.quantita),
                    "confirmedQuantity": Number(p.quantita),
                    "idSku": p.idSku,
                    "idItem": p.idItem,
                    "idCategory": p.idCategory,
                    "idDepartment": p.idDepartment,
                    "cost": p.prices[0].value,
                    "idTax": p.idTax,
                    "rowNumber": index
                }
            )
        }
        // delete data?.data[0].purchaseDocumentItems;
        // data?.data[0].purchaseDocumentItems.push()
        // for (let p of data?.data[0].purchaseDocumentItems) {
        //     delete p.category;
        //     delete p.department;
        //     delete p.sku;
        //     delete p.tax;
        // }
        mutate({
            resource: "updateDocumento",
            id: idDocumento,
            values: data?.data[0],
        });
        setTimeout(() => {
            navigate("/documentis")
        }, 1000)
        console.log(data?.data[0])
    }




    return (
        <>
            <div>
                <Form
                    form={form}
                    name="advanced_search"
                    className="ant-advanced-search-form"
                    onFinish={onFinish}
                >
                    <Row gutter={28}>
                        <Col span={6} key={'fornitore'}>
                            <Form.Item
                                name={'fornitore'}
                                label={'Fornitore'}
                            >

                                <Select {...fornitoreSelectProps} />
                            </Form.Item>
                        </Col>
                        <Col span={6} key={'data'}>
                            <Form.Item
                                name={'data'}
                                label={'Data'}
                            >

                                <DatePicker format={'DD/MM/YYYY'} onChange={() => onChange} />
                            </Form.Item>
                        </Col>
                        <Col span={4} key={'numeroOrdine'}>
                            <Form.Item
                                name={'numeroOrdine'}
                                label={'Numero Ordine'}
                            >

                                <Input placeholder="N°" />
                            </Form.Item>
                        </Col>
                        <Col span={6} key={'prodotto'}>
                            <Form.Item
                                name={'prodotto'}
                                label={'Prodotto'}
                            >

                                <Select placeholder={'Seleziona Prodotto'} {...prodotti.selectProps} allowClear={true} onSelect={(p:any) => addToCart(p)} />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button type="primary" onClick={() => saveData()}>Salva</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Table
                    dataSource={dataSource}
                    rowKey="externalId"
                    columns={columns}
                    scroll={{ y: 650 }}
                    size="small"
                    footer={() => (<>
                        <Row>
                            <Col span={20}>
                            </Col>
                            <Col span={4}>
                                <span>Totale: { Number(parseFloat(totale.toString()).toFixed(2)).toLocaleString('de', {minimumFractionDigits: 2}) } €</span>
                            </Col>
                        </Row>
                            <Row style={{marginTop: 15}}>
                                <Col span={20}>

                                </Col>
                                <Col span={4}>
                                    {/*<Button type="primary">Salva</Button>*/}
                                </Col>
                            </Row>
                    </>)}
                />
            </div>
            {/*<div>*/}
            {/*    <Row style={{marginTop: 15}}>*/}
            {/*        <Col span={22}>*/}
            {/*            <Button type="primary">Salva</Button>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</div>*/}
        </>
    );
};
