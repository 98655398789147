import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
  LoginPage,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import { DataProvider } from "@pankod/refine-strapi-v4";
import { useTranslation } from "react-i18next";
import { Header } from "components/layout";
import { authProvider, axiosInstance } from "./authProvider";
import { API_URL } from "./constants";
import {DashboardList} from './pages';
import {ParametriList} from './pages/parametris';
import {ListinoList} from './pages/listinos';
import {ListinoRiferimentoList} from './pages/listino-riferimentos';
import { OrderedListOutlined, SettingOutlined, DashboardOutlined } from '@ant-design/icons';
import {Login} from './pages/login';
import {ProdottiList} from './pages/prodottis';
import {DocumentiList} from './pages/documentis';
import {OrdiniList} from './pages/ordinis';
import {OrdiniEdit} from './pages/ordinis/edit';
import {FornitoriList} from './pages/fornitoris';

const { Link } = routerProvider;

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <Refine
      notificationProvider={notificationProvider}
      Layout={Layout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={routerProvider}
      authProvider={authProvider}
      dataProvider={DataProvider(API_URL + `/api`, axiosInstance)}
      LoginPage={Login}
      i18nProvider={i18nProvider}
      Title={({ collapsed }) => (
          <Link to="/">
              {collapsed ? (
                  <img
                      src="/images/logo_collapsed.png"
                      alt="Refine"
                      style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "8px 16px",
                      }}
                  />
              ) : (
                  <img
                      src="/images/logo.png"
                      alt="Refine"
                      style={{
                          width: "200px",
                          padding: "12px 24px",
                      }}
                  />
              )}
          </Link>
      )}
      Header={Header}
      resources={[
          { name: "dashboards", list: DashboardList, options: {label: 'Dashboard'}, icon: <DashboardOutlined />},
          { name: "prodottis", list: ProdottiList, options: { label: 'Prodotti'}, icon: <OrderedListOutlined />},
          { name: "documentis", list: DocumentiList, options: { label: 'Documenti'}, icon: <OrderedListOutlined />},
          { name: "fornitoris", list: FornitoriList, options: { label: 'Fornitori'}, icon: <OrderedListOutlined />},
          { name: "ordinis", edit: OrdiniEdit, list: OrdiniList, options: { label: 'Nuovo Ordine'}, icon: <OrderedListOutlined />},
          // { name: "listinos", list: ListinoList, options: {label: 'Listino'}, icon: <OrderedListOutlined />},
          // { name: "listino-riferimentos", list: ListinoRiferimentoList, options: {label: 'Listino Rif'}, icon: <OrderedListOutlined />},
          { name: "parametri", list: ParametriList, options: {label: 'Parametri'}, icon: <SettingOutlined />}]}
    />
  );
}

export default App;
